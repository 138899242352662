<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Managed devices
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <portal to="secondNavbarRight">
      <b-button
        v-if="validateIfHasPermission([
          permissions.RUN_EXECUTIONS.value.dbValue
        ]) && ( (checkedRows.length === 1 && deviceName) || (checkedRows.length> 1))"
        id="start-execution-devicelist"
        type="is-primary"
        style="margin: 0 5px"
        :disabled="checkedRows.length === 0"
        icon-left="lightning-bolt"
        @click="$router.push({ name: 'execute', params: { actionTab: 0, tabPage: 1 } })"
      >
        <span v-if="checkedRows.length === 1">
          Start execution in {{ deviceName }}
        </span>
        <span v-else>
          Start execution in {{ checkedRows.length }} devices
        </span>
      </b-button>
      <span class="no-wrap">
        <b-tooltip
          label="Refresh"
          type="is-dark"
          position="is-bottom"
        >
          <b-button
            type="is-white"
            @click="fetchApi(true)"
          >
            <b-icon
              icon="refresh"
            />
          </b-button>
        </b-tooltip>
        <b-dropdown
          id="reports-devicelist"
          class="is-bottom-left"
        >
          <button
            slot="trigger"
            class="button is-white"
          >
            <b-icon
              icon="dots-vertical"
            />
          </button>
          <b-dropdown-item
            custom
            class="title-dropdown"
          >
            <b>Table configuration</b>
          </b-dropdown-item>
          <b-dropdown-item
            @click="configureListIsOpen = true"
          >
            <a
              class="item-dropdown"
            >Hide, show or reorder columns</a>
          </b-dropdown-item>
          <b-dropdown-item
            @click="configureDevicesLimit = true"
          >
            <a
              class="item-dropdown"
            >Change device limit per page</a>
          </b-dropdown-item>
          <div>
            <hr
              class="dropdown-divider"
            >
            <b-dropdown-item
              custom
              class="title-dropdown"
            >
              <b>Export data</b>
            </b-dropdown-item>
            <b-dropdown-item
              :id="reportsNames.BASIC.value"
              @click="$router.push({ name: 'exports'})"
            >
              <a class="item-dropdown">
                Go to export data
              </a>
            </b-dropdown-item>
          </div>
          <div
            v-if="checkedRows.length > 1"
          >
            <hr
              class="dropdown-divider"
            >
            <b-dropdown-item
              custom
              class="title-dropdown"
            >
              <b>Remove devices</b>
            </b-dropdown-item>
            <b-dropdown-item
              :id="reportsNames.BASIC.value"
              @click="openRemoveDevicesModal"
            >
              <a class="item-dropdown">
                Remove selected devices
              </a>
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </span>
    </portal>
    <card
      v-if="filteredData.length !== 0 || hasDevices"
      external-card="material-card-overflow"
      internal-card="material-card material-card-content material-card-overflow"
      url-help=""
      style="overflow: visible"
    >
      <div
        slot="external-header"
        style="flex:1"
      >
        <b-modal
          :active.sync="configureListIsOpen"
        >
          <form>
            <div
              class="modal-card card modal-material-card"
              style="margin: auto; max-width: 500px"
            >
              <div class="modal-card-header">
                <p>Columns to show</p>
              </div>
              <section
                style="padding: 20px"
              >
                <b-table
                  :data="deviceListColumns.filter((x) => validateIfHasCapability(x.capability))"
                  :checked-rows.sync="checkedRowsList"
                  :is-row-checkable="(row) => !disableRows.includes(row.position)"
                  :row-class="(row) => disableRows.includes(row.position) && 'row-color'"
                  :mobile-cards="mobileCards"
                  checkbox-position="right"
                  checkable
                  draggable
                  striped
                  sticky-header
                  @check="(checkedList) => columnState(checkedList)"
                  @dragstart="dragstart"
                  @drop="drop"
                  @dragover="dragover"
                  @dragleave="dragleave"
                >
                  <b-table-column
                    v-slot="props"
                    field="title"
                    label="Column"
                  >
                    <span>
                      {{ props.row.title }}
                    </span>
                  </b-table-column>
                </b-table>
                <b-message
                  type="is-primary"
                  size="is-small"
                  :closable="false"
                  style="margin-top: 20px;"
                >
                  You can reorder the columns using drag and drop in the list above.
                </b-message>
              </section>
              <footer class="card-footer has-text-centered modal-card-footer">
                <button
                  class="button is-primary force-right"
                  type="button"
                  @click="configureListIsOpen = false"
                >
                  OK
                </button>
              </footer>
            </div>
          </form>
        </b-modal>
        <b-modal
          :active.sync="configureDevicesLimit"
        >
          <form>
            <div
              class="modal-card card modal-material-card"
              style="margin: auto; max-width: 500px"
            >
              <div class="modal-card-header">
                <p>Devices limit per page</p>
              </div>
              <section
                style="padding: 20px"
              >
                <div style="display: flex; gap: 8px;">
                  <b-button
                    v-for="limit in limitDevicesOptions"
                    :key="limit"
                    :type="limit === limitDevices ? 'is-primary' : 'is-outlined'"
                    @click="updateDeviceLimit(limit)"
                  >
                    {{ limit }}
                  </b-button>
                </div>
              </section>
              <footer class="card-footer has-text-centered modal-card-footer">
                <button
                  class="button is-primary force-right"
                  type="button"
                  @click="configureDevicesLimit = false"
                >
                  OK
                </button>
              </footer>
            </div>
          </form>
        </b-modal>
      </div>
      <div
        slot="body"
        style="display: block;"
      >
        <div
          v-if="dataFilters.length > 0"
          id="filters-table"
          style="display: inline-flex; flex-wrap: wrap; gap:5px 5px;"
        >
          <div
            v-for="(filter) of deviceListFiltersEnum"
            v-show="validateIfHasCapability(filter.requestedCapabilities)
              && dataFilters.find((x) => x.id === filter.id)
              && dataFilters.find((x) => x.id === filter.id).data.length > 0"
            :key="filter.id"
          >
            <searchable-dropdown
              v-if="dataFilters.find((x) => x.id === filter.id)"
              :parent-selected-items="selectedItems"
              :dropdown-properties="filter"
              :filter-objects="dataFilters.find((x) => x.id === filter.id).data"
              :on-update-parameters="updateSelectedFilters"
              :is-active-filter="filtersState[`${filter.id}Filter`].length > 0"
            />
          </div>
          <b-tooltip
            v-show="isActiveFilter"
            label="Reset filters"
            type="is-primary"
            style="border-radius: 4px"
            position="is-bottom"
          >
            <b-button
              type="is-primary"
              @click="clearFilters"
            >
              <b-icon
                icon="filter-off"
              />
            </b-button>
          </b-tooltip>
        </div>
        <div
          style="margin-top: 10px;"
        >
          <b-input
            id="searchInputDeviceField"
            v-model="searchInput.store"
            v-focus
            icon="magnify"
            type="search"
            placeholder="Search devices by name, serial, user or description"
            expanded
            autocomplete="off"
            @input="search"
          />
        </div>
        <b-notification
          v-if="!isFindDevicesPending && allCheck && totalData > limitDevices"
          :closable="false"
        >
          <p
            v-if="checkedRows.length !== totalData"
          >
            All <b>{{ limitDevices }}</b> devices on this page are selected.
            <a
              style="color:#632d8e; text-decoration: none;"
              @click="selectAllDevices"
            >
              Select {{ isActiveFilter ? '' : 'all ' }} <b>{{ totalData }}</b>
              {{ isActiveFilter ? ' devices that match selected filters' : 'devices' }}
            </a>
          </p>
          <p
            v-else
          >
            All <b> {{ totalData }} </b> {{ isActiveFilter ?
              'devices that match selected filters are selected.'
              : 'devices are selected.' }}
            <a
              style="color:#632d8e; text-decoration: none;"
              @click="clearSelected"
            >
              Clear selection
            </a>
          </p>
        </b-notification>
        <b-table
          v-if="loadedColumns"
          ref="deviceListTable"
          :data="filteredData"
          :mobile-cards="false"
          :striped="true"
          :paginated="totalData > limitDevices"
          :per-page="limitDevices"
          :default-sort="sortField"
          :default-sort-direction="sortDirection"
          :loading="(devicesResult.length === 0 && isFindDevicesPending) || isRemovingDevices"
          :current-page.sync="currentPage"
          hoverable
          class="deviceList"
          backend-pagination
          backend-sorting
          :total="totalData"
          @sort="sortTable"
          @check="onCheckRow"
          @page-change="changePagination"
        >
          <b-table-column
            sticky
            header-class="checkbox-header-container"
            cell-class="checkbox-cell-container"
          >
            <template
              v-slot:header
            >
              <div class="checkbox-header-container">
                <b-checkbox
                  :value="!isFindDevicesPending && allCheck"
                  :indeterminate="checkedRows.length > 0 && !allCheck"
                  @input="(value) => onCheckAll(value)"
                />
                <b-dropdown
                  aria-role="list"
                >
                  <template #trigger="{ active }">
                    <b-icon
                      :icon="active ? 'menu-up' : 'menu-down'"
                      class="icon-cursor"
                    />
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="onCheckAll(true)"
                  >
                    All
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="clearSelected"
                  >
                    None
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot="props">
              <b-checkbox
                :value="checkedRows.includes(props.row.id)"
                @input="(value) => onCheckRow(value,props.row.id, props.row.name)"
              />
            </template>
          </b-table-column>
          <b-table-column
            v-for="(column) in columnsTemplate
              .filter((x) => validateIfHasCapability(x.capability))"
            :key="column.id"
            :label="column.title"
            :visible="column.visible"
            :field="column.field"
            :sortable="column.isSortableColumn"
            :header-class="column.field === 'name' ? 'sticky-column' : ''"
            :cell-class="column.field === 'name' ? 'sticky-column' : ''"
          >
            <template v-slot="props">
              <span v-if="column.field === 'amtStatus'">
                <DeviceStatus
                  :key="props.row.id"
                  :status="getAMTColorStatus(props.row[column.field])"
                />
                {{ props.row.amtStatusText }}
              </span>
              <span v-else-if="column.field === 'status'">
                <DeviceStatus
                  :key="props.row.id"
                  :status="getAgentColorStatus(props.row[column.field])"
                />
                {{ props.row.statusText }}
              </span>
              <span v-else-if="column.field === 'tags'">
                <b-tag
                  v-for="tag in props.row.tags"
                  :key="tag"
                  type="is-primary"
                  class="deviceListTags"
                >{{ tag }}</b-tag>
              </span>
              <b-button
                v-else-if="column.field === 'name'"
                class="computer-name"
                type="is-ghost"
                @click="$router.push(`/${$route.params.companyId}/device/${props.row.id}`)"
              >
                <span>{{ props.row[column.field] }}</span>
                <b-tooltip
                  type="is-secondary"
                  label="Device details"
                  class="ml-3"
                >
                  <b-icon
                    icon="arrow-expand"
                    type="is-primary"
                    size="is-small"
                    class="expand-icon"
                  />
                </b-tooltip>
              </b-button>
              <span v-else-if="column.field === 'lastSeenUnified'">
                <span
                  v-if="props.row.amtStatus === amtStatus.ONLINE.value.dbValue
                    || props.row.amtStatus === amtStatus.CONNECTION_ERROR.value.dbValue
                    || props.row.status === deviceStatus.ONLINE.value.dbValue"
                >
                  Right Now
                </span>
                <timeago
                  v-else-if="props.row.lastSeenDateUnified"
                  :key="props.row.id"
                  :since="props.row.lastSeenDateUnified"
                  no-future
                  no-date
                />
                <span v-else>
                  Never
                </span>
              </span>
              <span v-else-if="column.field === 'lastSeenDateUnified'">
                <span
                  v-if="props.row.amtStatus === amtStatus.ONLINE.value.dbValue
                    || props.row.amtStatus === amtStatus.CONNECTION_ERROR.value.dbValue
                    || props.row.status === deviceStatus.ONLINE.value.dbValue"
                >
                  Right Now
                </span>
                <span v-else-if="props.row.lastSeenDateUnified">
                  {{ props.row.lastSeenDateUnified
                    | moment
                  }}
                </span>
                <span v-else>
                  Never
                </span>
              </span>
              <span v-else-if="column.field === 'lockStatus'">
                {{ props.row.lockStatusText }}
              </span>
              <span v-else-if="column.location">
                {{ props.row[column.field] }}
              </span>
              <span v-else-if="column.date">
                <span
                  v-if="column.isAgent && props.row.status === deviceStatus.ONLINE.value.dbValue"
                >
                  Right Now
                </span>
                <span
                  v-else-if="column.isAMT && (props.row.amtStatus === amtStatus.ONLINE.value.dbValue
                    || props.row.amtStatus === amtStatus.CONNECTION_ERROR.value.dbValue)"
                >
                  Right Now
                </span>
                <span v-else-if="props.row[column.field]">
                  {{ props.row[column.field] | moment }}
                </span>
                <span v-else>
                  Never
                </span>
              </span>
              <span v-else-if="column.timeAgo">
                <span
                  v-if="column.isAgent
                    && props.row.status === deviceStatus.ONLINE.value.dbValue"
                >
                  Right Now
                </span>
                <span
                  v-else-if="column.isAMT && (props.row.amtStatus === amtStatus.ONLINE.value.dbValue
                    || props.row.amtStatus === amtStatus.CONNECTION_ERROR.value.dbValue)"
                >
                  Right Now
                </span>
                <timeago
                  v-else-if="column.field !== 'amtLastSeen' || props.row[column.field]"
                  :key="props.row.id"
                  :since="props.row[column.field]"
                  no-future
                  no-date
                />
                <timeago
                  v-else-if="column.field === 'amtLastSeen' && props.row.amtLastSeenDate"
                  :key="props.row.id"
                  :since="props.row.amtLastSeenDate"
                  no-future
                  no-date
                />
                <span v-else>
                  Never
                </span>
              </span>
              <span v-else-if="column.field === 'type'">
                {{ props.row.typeText }}
              </span>
              <span v-else-if="column.field === 'freezeStatus'">
                {{ props.row.freezeStatusText }}
              </span>
              <span
                v-else-if="column.field === 'agentVersion'"
              >
                <span>{{ props.row.agentVersion }} </span>
                <span
                  v-if="agentLastVersion !== '0.0.0'"
                >
                  <span
                    v-if="currentCompany.planCapabilities.includes(planCapabilitiesEnum
                      .FIXED_AGENT_VERSION.value)"
                  >
                    <a
                      v-if="semverUi.compare(agentLastVersion, props.row.agentVersion) > 0
                        && props.row.status === deviceStatus.ONLINE.value.dbValue"
                      class="is-text link-style"
                      @click="updateAgent({
                        companyId: currentCompany.id,
                        deviceId: props.row.id,
                        userId: $store.getters['auth/user'].id,
                      })"
                    >
                      Update now
                    </a>
                    <a
                      v-else
                      class="is-text link-style"
                      @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);"
                    >
                      {{
                        0 >= semverUi.compare(agentLastVersion, props.row.agentVersion)
                          ? 'Latest' : 'Outdated'
                      }}
                    </a>
                  </span>
                  <span
                    v-else
                  >
                    <span
                      v-if="props.row.deviceGroupAgentVersion === '0'"
                    >
                      <a
                        v-if="semverUi.compare(agentLastVersion, props.row.agentVersion) > 0
                          && props.row.status === deviceStatus.ONLINE.value.dbValue"
                        class="is-text link-style"
                        @click="updateAgent({
                          companyId: currentCompany.id,
                          deviceId: props.row.id,
                          userId: $store.getters['auth/user'].id,
                        })"
                      >
                        Update now
                      </a>
                      <a
                        v-else
                        class="is-text link-style"
                        @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);"
                      >
                        {{
                          0 >= semverUi.compare(agentLastVersion, props.row.agentVersion)
                            ? 'Latest' : 'Outdated'
                        }}
                      </a>
                    </span>
                    <span
                      v-else
                      style="margin-left: 3px"
                    >
                      <a
                        v-if="props.row.status === deviceStatus.ONLINE.value.dbValue"
                        class="is-text link-style"
                        @click="updateAgent({
                          companyId: currentCompany.id,
                          deviceId: props.row.id,
                          userId: $store.getters['auth/user'].id,
                        })"
                      >
                        {{
                          0 >= semverUi.compare(props.row
                            .deviceGroupAgentVersion, props.row.agentVersion)
                            ? 'Latest' : 'Update now'
                        }}
                      </a>
                      <a
                        v-else
                        class="is-text link-style"
                        @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);"
                      >
                        {{
                          0 >= semverUi.compare(props.row
                            .deviceGroupAgentVersion, props.row.agentVersion)
                            ? 'Latest' : 'Outdated'
                        }}
                      </a>
                    </span>
                  </span>
                </span>
              </span>
              <span v-else-if="column.field === 'locationCreatedAt'">
                {{ getTimeThere(props.row.locationCreatedAt, props.row.lastLocated) }}
              </span>
              <span v-else-if="column.field === 'ipv4'">
                {{ props.row.deviceIPv4 }}
              </span>
              <span v-else-if="column.field === 'macAddress'">
                {{ props.row.deviceMacAddress }}
              </span>
              <span v-else>{{ props.row[column.field] }}</span>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </card>
    <download-vtul-agent
      v-else
      :company="currentCompany"
      :agent="1"
    />
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import { debounce, throttle } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import semver from 'semver';
import moment from 'moment';
import DeviceStatus from '@/components/device/DeviceStatus.vue';
import card from '@/components/cross/Card.vue';
import downloadVtulAgent from '@/components/dashboard/DownloadVtulAgent.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import ColorStatus from '@/helpers/colorStatus';
import parseEnums from '@/helpers/parseEnums';
import crossHelper from '@/helpers/cross';
import toastMessage from '@/helpers/toastMessage';
import AgentUpdatedMixin from '@/mixins/agentUpdated';
import SearchableDropdown from '@/components/cross/SearchableDropdown.vue';
import ExecutionsMixin from '@/mixins/executions';
import AnalyticsMixin from '@/mixins/analytics';
import CompanyMixin from '@/mixins/company';
import RoleMixin from '@/mixins/roles';
import vtulEnums from '../../../cross/index';
import deviceListColumns from '../config/deviceListColumns';
import deviceListFilters from '../config/deviceListFilters';

const { reportsNames } = vtulEnums.enum.report;
const { planCapabilities } = vtulEnums.enum.planCapabilities;
const { permissions } = vtulEnums.enum.roles;
const { encryptionStatus: deviceEncryptionStatusEnum } = vtulEnums.enum.bitlocker;
const { biosManagementStatus } = vtulEnums.enum.device;
const { programmerUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
  keys: ['name', 'hardwareSerial', 'lastUser', 'description'],
};

export default {
  name: 'DeviceList',
  metaInfo: {
    title: 'Devices',
  },
  components: {
    DeviceStatus,
    card,
    'searchable-dropdown': SearchableDropdown,
    downloadVtulAgent,
  },
  mixins: [AgentUpdatedMixin, CompanyMixin, RoleMixin, makeFindMixin({ service: 'devices', watch: true }), ExecutionsMixin, AnalyticsMixin],
  data() {
    return {
      limitDevices: this.getDeviceListPageSize,
      limitDevicesOptions: [20, 40, 80, 100],
      skipDevices: 0,
      currentPage: this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1,
      deviceStatus: vtulEnums.enum.device.deviceStatus,
      deviceStatusColor: vtulEnums.enum.device.deviceStatusColor,
      amtStatus: vtulEnums.enum.amt.amtStatus,
      deviceTypes: vtulEnums.enum.device.deviceTypes,
      lockStatus: vtulEnums.enum.device.deviceLockStatus,
      freezeStatusEnum: vtulEnums.enum.device.freezeStatus,
      antivirusEnumParser: parseEnums.enumToArray(vtulEnums.enum.device.antivirusStatus),
      firewallEnumParser: parseEnums.enumToArray(vtulEnums.enum.device.firewallStatus),
      deviceListFiltersEnum: parseEnums.enumToArray(deviceListFilters),
      planCapabilitiesEnum: planCapabilities,
      searchInput: {
        api: '',
        store: '',
      },
      filtersState: {
        isActiveFilter: false,
        statusFilter: [],
        agentVersionFilter: [],
        amtStatusFilter: [],
        vendorFilter: [],
        OSFilter: [],
        modelFilter: [],
        tagsFilter: [],
        cityFilter: [],
        regionFilter: [],
        countryFilter: [],
        typeFilter: [],
        deviceGroupFilter: [],
        encryptionStatusFilter: [],
        biosManagementStatusFilter: [],
        freezeStatusFilter: [],
      },
      filteredData: [],
      fuse: null,
      sortField: '',
      sortDirection: '',
      total: 0,
      reportsNames,
      parseEnums,
      semverUi: semver,
      crossHelper,
      tour: null,
      productTourStarted: false,
      attachToId: '#filters-table',
      checkedRows: [],
      deviceListColumns,
      mobileCards: false,
      planCapabilities,
      dataFilters: [],
      isActiveFilter: false,
      selectedFilters: {},
      configureListIsOpen: false,
      configureDevicesLimit: false,
      checkedRowsList: [],
      disableRows: [],
      loadedColumns: false,
      permissions,
      hasDevices: true,
      allCheck: false,
      deviceName: '',
      selectedColumns: ['id', 'companyId', 'enabled', ...deviceListColumns.filter((x) => x.isDataBaseColumn).map((x) => x.id)],
      devicesFetchFrequency: 1 * 60 * 1000,
      pageVisibilityEvent: {
        hidden: '',
        visibilityChange: '',
      },
      activeTab: true,
      refreshInterval: null,
      isRemovingDevices: false,
      companyCapabilities: [],
      messageVersion: '',
      selectedItems: {},
    };
  },
  computed: {
    ...mapGetters('devices', { findDevicesInStore: 'find', getSelectedDevices: 'getSelectedDevices' }),
    ...mapGetters('preferencesModule', ['getDeviceListColumns', 'getDeviceListOrder', 'getDeviceListPageSize']),
    currentPaginationQuery() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by devicesResult() method
      return {
        $limit: this.limitDevices,
        $skip: this.skipDevices,
      };
    },
    queryForSearchDevices() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by totalData() method.
      const sortDevices = this.sortForApi();
      const query = {
        $client: {
          associationsInfo: [
            { model: 'device-users', attributes: ['id', 'userName'] },
            { model: 'employee-profiles', attributes: ['id', 'name', 'agentVersion'] },
            { model: 'network-interfaces', attributes: ['id', 'IPv4', 'macAddress'] },
          ],
        },
        $sort: sortDevices,
        companyId: this.currentCompany ? this.currentCompany.id : undefined,
        enabled: true,
      };

      if (this.searchInput.api) {
        const columnsToSearch = [];
        fuseOptions.keys.forEach((column) => {
          columnsToSearch.push({
            [column]: { $ilike: this.searchInput.api },
          });
        });
        query.$or = columnsToSearch;
      }

      if (this.isActiveFilter) {
        if (this.selectedFilters.tagsFilter.length > 0) {
          const tagArray = [];
          const emptyTagsName = deviceListFilters.tag.value.itemNameWithoutData;
          this.selectedFilters.tagsFilter.forEach((tag) => {
            tagArray.push({
              tags: tag === emptyTagsName ? [] : { $contains: [tag] },
            });
          });
          query.$or = query.$or ? query.$or.concat(tagArray) : tagArray;
        }

        if (this.selectedFilters.OSFilter.length > 0) {
          const emptyOSName = deviceListFilters.operativeSystem.value.itemNameWithoutData;
          query.OS = {
            $in: this.selectedFilters.OSFilter.includes(emptyOSName)
              ? this.selectedFilters.OSFilter.concat('') : this.selectedFilters.OSFilter,
          };
        }
        if (this.selectedFilters.cityFilter.length > 0) {
          const emptyCityName = deviceListFilters.city.value.itemNameWithoutData;
          query.city = {
            $in: this.selectedFilters.cityFilter.includes(emptyCityName)
              ? this.selectedFilters.cityFilter.concat(null) : this.selectedFilters.cityFilter,
          };
        }
        if (this.selectedFilters.amtStatusFilter.length > 0) {
          query.amtStatus = {
            $in: this.selectedFilters.amtStatusFilter
              .includes(this.amtStatus.NOT_SUPPORTED.value.dbValue)
              ? this.selectedFilters.amtStatusFilter
                .concat(this.amtStatus.AMT_NOT_COMPATIBLE.value.dbValue)
              : this.selectedFilters.amtStatusFilter,
          };
        }
        if (this.selectedFilters.agentVersionFilter.length > 0) {
          query.agentVersion = {
            $in: this.selectedFilters.agentVersionFilter,
          };
        }

        if (this.selectedFilters.deviceGroupFilter.length > 0) {
          query.employeeProfileId = {
            $in: this.selectedFilters.deviceGroupFilter,
          };
        }

        if (this.selectedFilters.countryFilter.length > 0) {
          const emptyCountryName = deviceListFilters.country.value.itemNameWithoutData;
          query.country = {
            $in: this.selectedFilters.countryFilter.includes(emptyCountryName)
              ? this.selectedFilters.countryFilter.concat(null)
              : this.selectedFilters.countryFilter,
          };
        }

        query.enabled = true;
        if (this.selectedFilters.statusFilter.length > 0) {
          query.status = {
            $in: this.selectedFilters.statusFilter,
          };
        }
        if (this.selectedFilters.vendorFilter.length > 0) {
          const emptyVendorName = deviceListFilters.manufacturer.value.itemNameWithoutData;
          query.vendor = {
            $in: this.selectedFilters.vendorFilter.includes(emptyVendorName)
              ? this.selectedFilters.vendorFilter.concat('') : this.selectedFilters.vendorFilter,
          };
        }
        if (this.selectedFilters.modelFilter.length > 0) {
          const emptyModelName = deviceListFilters.model.value.itemNameWithoutData;
          query.model = {
            $in: this.selectedFilters.modelFilter.includes(emptyModelName)
              ? this.selectedFilters.modelFilter.concat('') : this.selectedFilters.modelFilter,
          };
        }
        if (this.selectedFilters.regionFilter.length > 0) {
          const emptyRegionName = deviceListFilters.region.value.itemNameWithoutData;
          query.region = {
            $in: this.selectedFilters.regionFilter.includes(emptyRegionName)
              ? this.selectedFilters.regionFilter.concat(null) : this.selectedFilters.regionFilter,
          };
        }
        if (this.selectedFilters.typeFilter.length > 0) {
          query.type = {
            $in: this.selectedFilters.typeFilter,
          };
        }
        if (this.selectedFilters.freezeStatusFilter.length > 0) {
          query.freezeStatus = {
            $in: this.selectedFilters.freezeStatusFilter,
          };
        }
        if (this.selectedFilters.encryptionStatusFilter.length > 0) {
          query.encryptionStatus = {
            $in: this.selectedFilters.encryptionStatusFilter,
          };
          if (this.selectedFilters.encryptionStatusFilter
            .includes(deviceEncryptionStatusEnum.NotCompatible.value.dbValue)) {
            query.and = [{
              $or: [
                { OSVendor: { $nin: ['Microsoft'] } },
                { encryptionStatus: query.encryptionStatus },
              ],
            }];
            delete query.encryptionStatus;
          }
        }
        if (this.selectedFilters.biosManagementStatusFilter.length > 0) {
          query.biosManagementStatus = {
            $in: this.selectedFilters.biosManagementStatusFilter,
          };
        }
      }
      return query;
    },
    devicesParams() {
      const queryWithoutSpecialFilters = JSON.parse(JSON.stringify(this.queryForSearchDevices));
      delete queryWithoutSpecialFilters.$client;
      queryWithoutSpecialFilters.$sort = this.sortAllDevices();
      if (queryWithoutSpecialFilters.$or) {
        delete queryWithoutSpecialFilters.$or;
      }
      return {
        query: {
          ...this.currentPaginationQuery,
          ...queryWithoutSpecialFilters,
        },
      };
    },
    devicesFetchParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchDevices,
        },
      };
    },
    devicesResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.devicesLatestQuery || !this.devicesPaginationData.default
          || !this.devicesPaginationData.default[this.devicesLatestQuery.queryId]
          || !this.devicesPaginationData.default[this.devicesLatestQuery.queryId][paginationId]) {
        this.findDevices();
        return [];
      }

      if (!this.currentCompany) {
        return [];
      }

      const { queryId } = this.devicesLatestQuery;
      let devicesIds = this.devicesPaginationData
        .default[queryId][paginationId].ids;
      if (this.currentPage === 1) {
        // This is a trick to display the new devices reactive
        let filterDevices = this.devices;
        if (this.selectedFilters.tagsFilter && this.selectedFilters.tagsFilter.length > 0) {
          filterDevices = this.devices.filter((x) => x.tags && x.tags.some(
            (y) => this.selectedFilters.tagsFilter.includes(y),
          ));
        }
        if (this.searchInput.store) {
          this.updateFuseData(filterDevices);
          filterDevices = this.fuse ? this.fuse.search(this.searchInput.store) : filterDevices;
        }
        devicesIds = filterDevices.map((x) => x.id).concat(devicesIds);
        devicesIds = [...new Set(devicesIds)];
      }

      const sortDevices = this.sortAllDevices();
      const devices = this.findDevicesInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: devicesIds,
          },
          $limit: this.limitDevices,
          $sort: sortDevices,
        },
      });
      return devices.data;
    },
    totalData() {
      if (!this.devicesLatestQuery || !this.devicesPaginationData.default
          || !this.devicesPaginationData.default[this.devicesLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.devicesLatestQuery;
      const { total } = this.devicesPaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitDevices);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }
      return total;
    },
    preparedDevices() {
      if (!this.currentCompany) return [];
      const dev = this.devicesResult;
      if (!dev) return [];

      const deviceStatusValues = this.parseEnums.enumToArray(this.deviceStatus);
      const amtStatusValues = this.parseEnums.enumToArray(this.amtStatus);
      const deviceTypesValues = this.parseEnums.enumToArray(this.deviceTypes);
      const deviceLockStatusValues = this.parseEnums.enumToArray(this.lockStatus);
      const freezeStatusValues = this.parseEnums.enumToArray(this.freezeStatusEnum);
      const finalDevices = [];
      dev.forEach((currentDevice) => {
        const device = currentDevice;
        device.typeText = deviceTypesValues
          .find((value) => value.dbValue === device.type).name;
        device.freezeStatusText = freezeStatusValues
          .find((value) => value.dbValue === device.freezeStatus).name;
        if (device.lockStatus) {
          if (device.lockStatus.status
              === this.lockStatus.DISPLAY_MESSAGES_FREQUENTLY.value.dbValue) {
            device.lockStatusText = `Display messages each ${device.lockStatus.frequency} minute${device.lockStatus.frequency > 1 ? 's' : ''}`;
          } else {
            device.lockStatusText = deviceLockStatusValues
              .find((value) => value.dbValue === device.lockStatus.status).name;
          }
        } else {
          device.lockStatusText = this.lockStatus.UNLOCKED.value.name;
        }

        device.statusText = deviceStatusValues
          .find((value) => value.dbValue === device.status).name;

        device.amtStatus = (device.OSVendor === 'Apple' || !device.amtVersion || device.amtVersion === '0')
          ? this.amtStatus.NOT_SUPPORTED.value.dbValue : device.amtStatus;
        device.amtStatusText = amtStatusValues
          .find((value) => value.dbValue === device.amtStatus).name;

        device.lastSeenDate = device.lastSeen;
        device.amtLastSeenDate = device.amtLastSeen;
        if (device.amtLastSeenDate && device.amtLastSeenDate > device.lastSeenDate) {
          device.lastSeenDateUnified = device.amtLastSeenDate;
        } else {
          device.lastSeenDateUnified = device.lastSeenDate;
        }
        if (device.device_users) {
          device.deviceUsers = device.device_users.map((u) => u.userName).join(', ');
        }
        if (device.network_interfaces) {
          device.deviceIPv4 = device.network_interfaces.map((u) => u.IPv4).filter(Boolean).join(', ');
          device.deviceMacAddress = device.network_interfaces.map((u) => u.macAddress).filter(Boolean).join(', ');
        }
        if (device.employee_profile) {
          device.deviceGroup = device.employee_profile.name;
          device.deviceGroupAgentVersion = device.employee_profile.agentVersion;
        }
        if (device.OSVendor !== 'Microsoft') {
          device.encryptionStatus = deviceEncryptionStatusEnum.NotCompatible.value.dbValue;
        }
        if (device.encryptionStatus !== null && typeof (device.encryptionStatus) === 'number') {
          device.encryptionStatus = deviceEncryptionStatusEnum.enums.find(
            (value) => value.value.dbValue === device.encryptionStatus,
          ).value.name;
        }

        if (typeof (device.biosManagementStatus) === 'number') {
          if (device.biosManagementStatus === biosManagementStatus.OTHERS.value.dbValue) {
            device.biosManagementStatus = device.biosPasswordValidation;
          } else {
            device.biosManagementStatus = biosManagementStatus.enums.find(
              (x) => device.biosManagementStatus === x.value.dbValue,
            ).value.name;
          }
        }
        finalDevices.push(device);
      });
      return JSON.stringify(finalDevices);
    },
    columnsTemplate() {
      const preferences = this.getDeviceListColumns;

      this.deviceListColumns.forEach((column, index) => {
        const preference = preferences.find((x) => x.id === column.id);
        this.deviceListColumns[index].visible = preference ? preference.visible : false;
      });
      return this.deviceListColumns;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  watch: {
    preparedDevices() {
      this.allCheck = JSON.parse(this.preparedDevices)
        .every((val) => this.checkedRows.includes(val.id));
      this.updateUIData();
    },
    currentPage(newPage) {
      this.$router.replace(`/${this.$route.params.companyId}/devices/${newPage}`);
    },
    filtersState: {
      handler(state) {
        const filters = JSON.parse(JSON.stringify(state));
        delete filters.isActiveFilter;
        this.isActiveFilter = false;
        this.allCheck = false;
        if (Object.values(filters).some((element) => element.length > 0)) {
          this.isActiveFilter = true;
        }
        this.selectedFilters = state;
      },
      deep: true,
    },
    checkedRows: {
      handler() {
        this.allCheck = JSON.parse(this.preparedDevices)
          .every((val) => this.checkedRows.includes(val.id));
      },
      deep: true,
    },
  },
  created() {
    /* Get sort from localstorage */
    this.companyHasDevices();
    this.prepareDataToFilters();
    this.sortField = this.getDeviceListOrder.field;
    this.sortDirection = this.getDeviceListOrder.direction;
    this.limitDevices = this.getDeviceListPageSize;
    this.updateUIData(); // Update from Local Storage
    this.changePagination(this.currentPage);
    this.refreshInterval = setInterval(
      () => { this.refresh(); }, this.devicesFetchFrequency,
    );
    this.setNavigatorEventName();
    document.addEventListener(this.pageVisibilityEvent.visibilityChange,
      this.handleVisibilityChange, false);
  },
  mounted() {
    // this.startProductTour();
    this.deviceListColumns.forEach((column) => {
      if (column.disabled) {
        this.disableRows.push(column.position);
      }
    });
    this.getDeviceListColumns.forEach((column) => {
      if (column.visible) {
        const row = this.deviceListColumns.find((x) => x.id === column.id);
        row.position = column.position;
        this.checkedRowsList.push(row);
      }
    });
    this.columnState(this.checkedRowsList);
    this.loadedColumns = true;
  },
  beforeDestroy() {
    if (this.productTourStarted && this.tour) {
      this.tour.complete();
      if (this.tour.getCurrentStep().options.attachTo.element === this.attachToId) {
        this.endTour();
      }
    }
  },
  destroyed() {
    clearInterval(this.refreshInterval);
    document.removeEventListener(
      this.pageVisibilityEvent.visibilityChange, this.handleVisibilityChange,
    );
  },
  methods: {
    ...mapActions('devices', { getDevice: 'get', patchDeviceInfo: 'patch' }),
    ...mapActions('devices', ['updateSelectedDevices', 'deleteStore']),
    ...mapActions('preferencesModule', ['updateDeviceListColumns', 'updateDeviceListOrder', 'updateDeviceListPageSize']),
    ...mapActions('user', { patchUser: 'patch' }),
    ...mapActions('devices-filters', { findFilters: 'find' }),
    ...mapActions('devices-totals', { getDevicesTotal: 'get', findAllDevices: 'find' }),
    ...mapActions('update-channels', { updateChannel: 'update' }),
    /* The dragstart, dragover, dragleave and drop functions can't be removed, according to the
    documentation of buefy they are requiered for the rows to be draggable  */
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      // eslint-disable-next-line no-param-reassign
      payload.event.dataTransfer.effectAllowed = 'copy';
    },
    dragover(payload) {
      // eslint-disable-next-line no-param-reassign
      payload.event.dataTransfer.dropEffect = 'copy';
      payload.event.target.closest('tr').classList.add('is-selected');
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected');
      payload.event.preventDefault();
    },
    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected');
      const droppedOnRowIndex = payload.index;
      if (!this.deviceListColumns[this.draggingRowIndex].disabled
        && !this.deviceListColumns[droppedOnRowIndex].disabled
        && this.deviceListColumns[this.draggingRowIndex].visible) {
        this.deviceListColumns.splice(
          droppedOnRowIndex,
          0,
          ...this.deviceListColumns.splice(this.draggingRowIndex, 1),
        );
        this.deviceListColumns.forEach((column, index) => {
          this.deviceListColumns[index].position = index + 1;
        });
        this.saveColumnPreferences(this.checkedRowsList);
      }
    },
    columnState(columnList) {
      const compare = [];
      const disableColumns = [];
      columnList.forEach((column) => {
        compare.push(column.id);
      });
      const visibleColumns = [];
      this.deviceListColumns.forEach((column) => {
        if (compare.includes(column.id)) {
          visibleColumns.push(column);
        } else {
          disableColumns.push(column);
        }
      });
      this.deviceListColumns = [];
      this.deviceListColumns = columnList[0].name ? visibleColumns.concat(disableColumns)
        : columnList.concat(disableColumns);
      this.saveColumnPreferences(columnList);
    },
    saveColumnPreferences(columnList) {
      const compare = [];
      const preferences = [];
      columnList.forEach((column) => {
        compare.push(column.id);
      });
      this.deviceListColumns.forEach((column, index) => {
        this.deviceListColumns[index].position = index + 1;
        preferences.push({
          id: column.id,
          visible: compare.includes(column.id),
          position: column.position,
        });
      });
      this.updateDeviceListColumns(preferences);
    },
    async changePagination(page) {
      if (page) {
        if (page !== this.currentPage) {
          await this.deleteStore();
        }
        this.currentPage = page;
      }
      this.skipDevices = (this.currentPage - 1) * this.limitDevices;
    },
    setNavigatorEventName() {
      if (typeof document.hidden !== 'undefined') {
        this.pageVisibilityEvent.hidden = 'hidden';
        this.pageVisibilityEvent.visibilityChange = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        this.pageVisibilityEvent.hidden = 'msHidden';
        this.pageVisibilityEvent.visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.hidden = 'webkitHidden';
        this.pageVisibilityEvent.visibilityChange = 'webkitvisibilitychange';
      }
    },
    handleVisibilityChange() {
      if (document[this.pageVisibilityEvent.hidden]) {
        this.activeTab = false;
      } else {
        this.activeTab = true;
        this.refresh();
      }
    },
    refresh() {
      if ((this.lastDevicesFetch && (Date.now() - this.lastDevicesFetch) < (50 * 1000))
        || !this.activeTab) {
        return;
      }
      this.fetchApi();
    },
    fetchApi: throttle(function fetchApi(isFromEvent) {
      if (isFromEvent) {
        toastMessage.showInfo('Loading...', 500);
      }
      this.lastDevicesFetch = Date.now();
      this.findDevices();
      this.prepareDataToFilters();
    }, 1000),
    updateFuseData(data) {
      this.fuse = new Fuse(data, fuseOptions);
    },
    updateOrderColumns(columns) {
      this.deviceListColumns = columns;
    },
    getAMTColorStatus(status) {
      return ColorStatus.getAMTColorStatus(status);
    },
    getAgentColorStatus(status) {
      return ColorStatus.getAgentColorStatus(status);
    },
    updateSelectedFilters(params, filterSettings) {
      this.filtersState[`${filterSettings.id}Filter`] = params.map((x) => x[filterSettings.filterKey || filterSettings.id]);
    },
    clearFilters() {
      this.filtersState = {
        statusFilter: [],
        agentVersionFilter: [],
        amtStatusFilter: [],
        vendorFilter: [],
        OSFilter: [],
        modelFilter: [],
        tagsFilter: [],
        cityFilter: [],
        regionFilter: [],
        countryFilter: [],
        typeFilter: [],
        deviceGroupFilter: [],
        encryptionStatusFilter: [],
        biosManagementStatusFilter: [],
        freezeStatusFilter: [],
        isActiveFilter: false,
      };
    },
    search: debounce(function search() {
      if (this.searchInput.store) {
        this.searchInput.api = `%${this.searchInput.store.toLowerCase()}%`;
      } else {
        this.searchInput.api = '';
      }
    }, 500),
    onCheckRow(value, id) {
      if (value) {
        this.checkedRows.push(id);
      } else {
        const index = this.checkedRows.findIndex((x) => x === id);
        if (index !== -1) {
          this.checkedRows.splice(index, 1);
        }
      }
      this.updateSelectedDevices({ devices: this.checkedRows, companyId: this.currentCompany.id });
      this.updateUIData();
    },
    onCheckAll(value) {
      if (value) {
        this.checkedRows = [...new Set(this.checkedRows.concat(
          ...JSON.parse(this.preparedDevices).map((x) => x.id),
        ))];
      } else {
        this.checkedRows = [];
      }
      this.updateSelectedDevices({ devices: this.checkedRows, companyId: this.currentCompany.id });
    },
    async selectAllDevices() {
      const query = JSON.parse(JSON.stringify(this.queryForSearchDevices));
      delete query.$client;
      query.$select = ['id'];
      query.$sort = { name: [1, 1] };
      const { data } = await this.findAllDevices({ query });
      const selectedDevicesIds = data.map((x) => x.id);
      this.checkedRows = selectedDevicesIds;
      await this.updateSelectedDevices({
        devices: selectedDevicesIds,
        companyId: this.currentCompany.id,
      });
    },
    async updateUIData() {
      const { selectedDevices } = await this.getSelectedDevices(this.currentCompany.id);
      this.checkedRows = selectedDevices || [];
      if (this.checkedRows.length === 1) {
        let selectedDevice = JSON.parse(this.preparedDevices)
          .find((x) => x.id === this.checkedRows[0]);
        if (!selectedDevice) {
          selectedDevice = await this.getDevice([this.checkedRows[0], {
            query: {
              companyId: this.currentCompany.id,
            },
          }]);
        }
        // We must validate if device is enabled out of query otherwise query fail
        if (selectedDevice && selectedDevice.enabled) {
          this.deviceName = selectedDevice.name;
        } else {
          this.deviceName = '';
          this.checkedRows = [];
          this.updateSelectedDevices({ devices: [], companyId: this.currentCompany.id });
        }
      }
      this.filteredData = JSON.parse(this.preparedDevices);
      this.updateChannel([this.user.id, {
        devicesIds: JSON.parse(this.preparedDevices).map((device) => device.id),
        companyId: this.currentCompany.id,
        events: ['device'],
      }]);
    },
    prepareDataToFilters: throttle(async function prepareDataToFilters() {
      const currentFilters = JSON.parse(JSON.stringify(this.filtersState));
      delete currentFilters.isActiveFilter;
      this.selectedItems = Object.values(currentFilters).some((element) => element.length > 0)
        ? this.filtersState : {};
      const allFilters = deviceListFilters;
      const availableFilters = [];
      allFilters.enums.forEach((setting) => {
        if (this.validateIfHasCapability(setting.value.requestedCapabilities)) {
          availableFilters.push(setting.value);
        }
      });
      const filters = await this.findFilters({
        query: {
          companyId: this.currentCompany.id,
          availableFilters,
        },
      });
      this.dataFilters = filters.data;
    }, 2000),
    clearSelected() {
      this.allCheck = false;
      this.checkedRows = [];
      this.updateSelectedDevices({
        devices: [],
        companyId: this.currentCompany.id,
      });
    },
    sortTable(field, direction) {
      this.sortDirection = direction;
      this.sortField = field;
      const newOrder = {
        field, direction,
      };
      this.updateDeviceListOrder(newOrder);
    },
    updateDeviceLimit(newLimit) {
      this.limitDevices = newLimit;
      this.updateDeviceListPageSize(newLimit);
    },
    sortAllDevices() {
      let sortDevices = {};
      const currentColumn = this.columnsTemplate.find((x) => x.field === this.sortField);
      if (currentColumn && currentColumn.isSortableColumn) {
        if (currentColumn.id === 'amtLastSeenDate') {
          sortDevices.amtLastSeen = this.sortDirection === 'asc' ? 1 : -1;
        } else if (currentColumn.id === 'lastSeenDate') {
          sortDevices.lastSeen = this.sortDirection === 'asc' ? 1 : -1;
        } else {
          sortDevices[this.sortField] = this.sortDirection === 'asc' ? 1 : -1;
        }
      }
      if (sortDevices.lastSeen || sortDevices.amtLastSeen) {
        sortDevices = ({ status: this.sortDirection === 'asc' ? 1 : -1, ...sortDevices });
      }
      return sortDevices;
    },
    sortForApi() {
      let sortForApi = {};
      Object.entries(this.sortAllDevices()).forEach((entry) => {
        const [key, value] = entry;
        // Function to structure the API query to organize NULL values
        sortForApi = ({ ...sortForApi, [key]: [value, value] });
      });
      return sortForApi;
    },
    startProductTour() {
      if (!this.user.productTourStatus.deviceListTour && !this.productTourStarted) {
        this.tour = this.$shepherd({
          useModalOverlay: true,
        });
        this.productTourStarted = true;
        this.tour.addStep({
          attachTo: { element: '#company-dropdown', on: 'top' },
          classes: 'product-tour-correction-in-x',
          text: 'Learn more options here!',
          buttons: [
            {
              classes: 'button is-small is-light',
              text: 'Skip',
              action: this.endTour,
            },
            {
              classes: 'button is-small is-primary button-to-right',
              text: 'Next',
              action: this.tour.next,
            },
          ],
          isCentered: true,
        });
        this.tour.addStep({
          attachTo: { element: '#filters-table', on: 'top' },
          classes: 'product-tour-correction-in-x',
          text: 'Here you can see a list of your company\'s devices. Selecting any device, you can see all its characteristics. Choose one!',
          isCentered: true,
          buttons: [
            {
              classes: 'button is-small is-outlined is-primary button-to-right',
              text: 'Back',
              action: this.tour.back,
            },
            {
              classes: 'button is-small is-primary',
              text: 'Done',
              action: this.endTour,
            },
          ],
        });
        this.tour.start();
      }
    },
    endTour() {
      this.productTourStarted = false;
      this.tour.complete();
      this.updateProductTourStatus();
    },
    async updateProductTourStatus() {
      this.user.productTourStatus.deviceListTour = true;
      this.patchUser([this.user.id, {
        productTourStatus: this.user.productTourStatus,
      }]);
    },
    async companyHasDevices() {
      const result = await this.getDevicesTotal(this.currentCompany.id);
      this.hasDevices = !!result.totalDevices;
    },
    getTimeThere(locationCreatedAt, lastLocated) {
      if (!locationCreatedAt || !lastLocated) {
        return '';
      }
      return moment.duration(moment(lastLocated).subtract(moment(locationCreatedAt))).humanize();
    },
    async removeDevices() {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Remove Multiple Devices', {
          companyName: this.currentCompany.name,
          companyAddress: this.currentCompany.address,
          companyCity: this.currentCompany.city,
          companyCountry: this.currentCompany.country,
          companyStripeCustomerId: this.currentCompany.stripeCustomerId,
          companyUrl: this.currentCompany.url,
          devicesAmount: this.checkedRows.length,
        });
      try {
        this.isRemovingDevices = true;
        this.createExecutions(
          this.currentCompany.id,
          this.user.id,
          this.checkedRows,
          vtulEnums.enum.execution.executeAction.UNINSTALL_AGENT.value.id,
          null,
        );
        await this.patchDeviceInfo([null, {
          enabled: false,
          status: this.deviceStatus.UNKNOWN.value.dbValue,
        }, {
          query: {
            companyId: this.currentCompany.id,
            id: { $in: this.checkedRows },
          },
        }]);

        this.checkedRows = [];
        this.updateSelectedDevices({
          devices: this.checkedRows,
          companyId: this.currentCompany.id,
        });
        this.isRemovingDevices = false;
        this.fetchApi();
        toastMessage.showSuccess('The devices were removed successfully.');
      } catch (e) {
        this.isRemovingDevices = false;
        this.throwVtulError(programmerUiErrors.REMOVE_DEVICES_ERROR, alertBox.SNACKBAR.value, e);
      }
    },
    openRemoveDevicesModal() {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: () => this.removeDevices(),
          title: 'Confirm remove multiple devices from Boardgent',
          message: `This action cannot be undone. You will not be able to manage this computers because the Boardgent Agent will be uninstalled.  If you need to manage them in the future, you will have to reinstall the Boardgent Agent manually.
          <br><br> Enter the number of devices to be removed <b>(${this.checkedRows.length})</b> to confirm`,
          confirmText: 'Confirm',
          expectedText: `${this.checkedRows.length}`,
        },
      });
    },
  },
};
</script>

<style>
  .computer-name {
    width: 100%;
    justify-content: start;
    position: relative;
    text-decoration: none !important;
    color: #363636 !important;
  }

  .computer-name :hover {
    color: #363636 !important;
  }

  .deviceList {
    white-space: nowrap;
    margin-top: 5px;
  }
  .deviceList > .table-wrapper {
    overflow-x: auto;
    min-height: 124px;
  }
  .options-button:hover {
    background-color: white !important;
  }
  .toggle-row {
    padding: 5px 0px !important;
  }
  .toggle-row .column {
    padding: 0px 0.75rem !important;
  }
  .switch {
    margin: 0px 0.5em;
  }
  .dropdown + .dropdown {
    margin: 0px;
  }
  .center-status {
    flex-direction: row;
    align-items: center;
  }
  .filter-buttons div button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .deviceListTags {
    margin: 0px 4px;
  }
  .checkbox-style {
    font-size: 10px;
    justify-content: flex-start;
    margin-right: 10px;
  }
  .margin-button {
    margin-left: 4px;
  }

  tr.row-color {
      background: #dadadd;
  }

  #iconButton > span {
    display:flex;
  }

  .notification {
    text-align: center;
    padding: 0.5em;
    margin-top: 10px !important;
  }

  .checkbox-header-container {
    width: 40px !important;
    background: white!important;
    padding-right: 0 !important;
  }

  .checkbox-header-container label {
    margin-right: 0px;
  }

  .checkbox-header-container .dropdown {
    position: relative;
    right: 20px;
  }

  .checkbox-header-container .dropdown-trigger {
    padding: 0px;
    right: 20px;
  }

  .checkbox-header-container .icon {
    margin: 0px !important;
    height: 20px !important;
  }

  .checkbox-cell-container{
    padding-right: 0 !important;
  }

  .icon-cursor {
    cursor: pointer;
  }

  .sticky-column {
    position: sticky;
    left: 52px;
    border-right: 1px solid rgba(0,0,0,0.1) !important;
    background-color: #fff;
    z-index: 2;
  }

  .sticky-column:hover .expand-icon {
    visibility: inherit;
  }

  .expand-icon {
    visibility: hidden;
    padding: 2px;
    border-radius: 50%;
    background-color: #dbdbdb
  }

  .expand-icon:hover {
    background-color: #cecece;
  }

  .deviceList table tbody tr:nth-child(even) td:nth-child(2)  {
    background-color: #fafafa;
  }

  .deviceList table tbody td:nth-child(2) button{
    padding: 0 5px;
    height: 32px;
  }

  .deviceList table tbody td:nth-child(2){
    padding: 4px 12px;
  }

  .deviceList table tbody tr:hover .expand-icon{
    visibility: inherit;
  }

  @media screen and (min-width: 1065px) {
    .pulled-right {
      float: right !important;
    }
  }
  @media screen and (max-width: 1065px) {
    .pulled-right {
      margin-top: 5px;
    }
  }
  @media screen and (min-width: 400px) {
    .medium {
      font-size: 1.25rem;
    }
  }
</style>
