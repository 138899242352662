import bgEnums from '../../../cross/index';

const Enum = require('enum');

const { planCapabilities } = bgEnums.enum.planCapabilities;

export default
new Enum({
  deviceGroup: {
    id: 'deviceGroup',
    filterKey: 'id',
    propertyToShow: 'name',
    label: 'Group',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  agent: {
    id: 'status',
    propertyToShow: 'name',
    label: 'Agent',
    showStatus: true,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: true,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  amt: {
    id: 'amtStatus',
    propertyToShow: 'name',
    label: 'AMT',
    showStatus: true,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: true,
    requestedCapabilities: [planCapabilities.AMT_MANAGEMENT.value],
  },
  type: {
    id: 'type',
    propertyToShow: 'name',
    label: 'Type',
    showStatus: false,
    icon: {
      showIcon: true,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  manufacturer: {
    id: 'vendor',
    propertyToShow: 'vendor',
    label: 'Manufacturer',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  operativeSystem: {
    id: 'OS',
    propertyToShow: 'OS',
    label: 'OS',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  model: {
    id: 'model',
    propertyToShow: 'model',
    label: 'Model',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  city: {
    id: 'city',
    propertyToShow: 'city',
    label: 'City',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
  },
  region: {
    id: 'region',
    propertyToShow: 'region',
    label: 'Region',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
  },
  country: {
    id: 'country',
    propertyToShow: 'country',
    label: 'Country',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
  },
  agentVersion: {
    id: 'agentVersion',
    propertyToShow: 'agentVersion',
    label: 'Agent version',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: true,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  tag: {
    id: 'tags',
    filterKey: 'tag',
    propertyToShow: 'tag',
    label: 'Tags',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Empty',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
  },
  encryptionStatus: {
    id: 'encryptionStatus',
    filterKey: 'encryptionStatus',
    propertyToShow: 'name',
    additionalColumn: 'OSVendor',
    label: 'BitLocker',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Empty',
    requestedCapabilities: [
      planCapabilities.DISK_ENCRYPTION.value,
    ],
  },
  biosManagementStatus: {
    id: 'biosManagementStatus',
    filterKey: 'biosManagementStatus',
    propertyToShow: 'name',
    label: 'BIOS/UEFI',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Not managed',
    requestedCapabilities: [
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
    ],
  },
  freezeStatus: {
    id: 'freezeStatus',
    filterKey: 'freezeStatus',
    propertyToShow: 'name',
    label: 'Freeze status',
    showStatus: false,
    icon: {
      showIcon: false,
    },
    canSelectMultiple: true,
    customSort: false,
    itemNameWithoutData: 'Unknown',
    requestedCapabilities: [
      planCapabilities.UWF.value,
    ],
  },
});
